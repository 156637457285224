import classNames from 'classnames';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import { useNotifications } from '../../../entities/Notifications';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll';
import { useNavigate } from '../../../hooks/useNavigate';
import {
  AuthRoutes,
  EduRoutes,
  MainRoutes,
  ProfileRoutes,
  WeberRoutes,
  WizRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { NotificationType, TNotifications } from '../../../models/Notifications';
import { NotificationCard } from '../../../widgets/NotificationCard';
import { ReactComponent as AddUserIcon } from '../assets/icons/add-user.svg';
import { ReactComponent as BeakerIcon } from '../assets/icons/beaker.svg';
import { ReactComponent as BookIcon } from '../assets/icons/book.svg';
import { ReactComponent as ChatIcon } from '../assets/icons/chat.svg';
import { ReactComponent as CommentIcon } from '../assets/icons/comment.svg';
import { ReactComponent as GroupIcon } from '../assets/icons/group.svg';
import { ReactComponent as NewspaperIcon } from '../assets/icons/newspaper.svg';
import { ReactComponent as RewiewIcon } from '../assets/icons/review.svg';
import { ReactComponent as StarIcon } from '../assets/icons/star.svg';
import { ReactComponent as NotifyIcon } from '../../../assets/icons/menu/notify.svg';
import './Notifications.scss';
interface NotificationsProps {
  className?: string;
  onClose?: () => void;
}

const icons: Record<NotificationType, React.FC> = {
  FRIENDSHIP_REQUEST: AddUserIcon,
  MESSAGE: ChatIcon,
  JOINED_GROUP: GroupIcon,
  JOIN_GROUP_REQUEST: GroupIcon,
  LEFT_GROUP: GroupIcon,
  POST_REACTION: RewiewIcon,
  ER_POINTS_INCOME: StarIcon,
  PURCHASE_COMPLETION: BookIcon,
  NEW_REVIEW: RewiewIcon,
  COURSE_PUBLISHED: BookIcon,
  COURSE_REJECTED: BookIcon,
  DAO_MEMBER_SHIP_GROWTH: BeakerIcon,
  POST_PUBLICATION: NewspaperIcon,
  NEW_MESSAGE_RECEIVED: ChatIcon,
  FRIEND_REQUEST_RECEIVED: AddUserIcon,
  COMMENT_RESPONSE: CommentIcon,
  FRIEND_REQUEST_ACCEPTED: AddUserIcon,
  POST_COMMENT_GROWTH: RewiewIcon,
  RESTORING_LIVES: StarIcon,
  REFERRAL_OWNER_REWARD: StarIcon,
  REFERRAL_ACTIVATOR_REWARD: StarIcon,
};

interface UrlConfig {
  url: string;
  needsId: boolean;
}

type GroupedNotifications = {
  today: TNotifications[];
  thisWeek: TNotifications[];
  thisMonth: TNotifications[];
  otherDays: TNotifications[];
};

const urls: Record<NotificationType, UrlConfig> = {
  // Posts
  POST_REACTION: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },
  POST_COMMENT_GROWTH: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },
  POST_PUBLICATION: { url: MainRoutes.weber + WeberRoutes.feed, needsId: true },

  // Courses
  PURCHASE_COMPLETION: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  NEW_REVIEW: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COURSE_PUBLISHED: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COURSE_REJECTED: { url: MainRoutes.edu + EduRoutes.course, needsId: true },
  COMMENT_RESPONSE: { url: MainRoutes.edu + EduRoutes.course, needsId: true },

  // Chats
  MESSAGE: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat,
    needsId: false,
  },
  NEW_MESSAGE_RECEIVED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.chat,
    needsId: false,
  },

  // Communities
  JOINED_GROUP: { url: MainRoutes.weber + WeberRoutes.community, needsId: true },
  JOIN_GROUP_REQUEST: {
    url: MainRoutes.weber + WeberRoutes.community,
    needsId: true,
  },
  LEFT_GROUP: { url: MainRoutes.weber + WeberRoutes.community, needsId: true },

  // Wallet
  ER_POINTS_INCOME: {
    url: '',
    needsId: false,
  },

  // DAO
  DAO_MEMBER_SHIP_GROWTH: { url: '', needsId: false },

  // Friends
  FRIENDSHIP_REQUEST: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
  FRIEND_REQUEST_RECEIVED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },
  FRIEND_REQUEST_ACCEPTED: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.networking,
    needsId: false,
  },

  RESTORING_LIVES: {
    url: MainRoutes.wiz + WizRoutes.quiz,
    needsId: false,
  },
  REFERRAL_OWNER_REWARD: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user,
    needsId: true,
  },
  REFERRAL_ACTIVATOR_REWARD: {
    url: MainRoutes.auth + AuthRoutes.profile + ProfileRoutes.user,
    needsId: true,
  },
};

export const Notifications = forwardRef<HTMLDivElement, NotificationsProps>(
  ({ className, onClose }, ref) => {
    const { t } = useTranslation(['translation']);
    const { fetchData, isLoading, totalPagesCount, notifications } =
      useNotifications();
    const navigate = useNavigate();
    const [currentPage, setPage] = useState(1);

    useEffect(() => {
      fetchData(1);
    }, [t]);

    const getNotificationIcon = (type: NotificationType) => {
      const IconComponent = icons[type];
      return <IconComponent />;
    };

    const handleNotificationClick = (url: string | null) => url && navigate(url);

    const getNotificationUrl = (
      constant: NotificationType,
      id?: number | string
    ) => {
      const { url, needsId } = urls[constant];
      if (url === '') {
        return null;
      }
      return needsId && id ? `${url}/${id}` : url;
    };

    const loadComments = useCallback(() => {
      if (totalPagesCount > currentPage && !isLoading) {
        setPage(currentPage + 1);
        fetchData(currentPage + 1);
      }
    }, [totalPagesCount, currentPage, isLoading]);

    const targetRef = useRef<HTMLDivElement>(null);
    useInfiniteScroll(targetRef, loadComments);

    const groupNotificationsByTime = (
      notifications: TNotifications[]
    ): GroupedNotifications => {
      const now = new Date();
      const startOfToday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );

      const startOfWeek = new Date(startOfToday);
      const dayOfWeek = startOfWeek.getDay() === 0 ? 7 : startOfWeek.getDay();
      startOfWeek.setDate(startOfWeek.getDate() - (dayOfWeek - 1));

      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

      const groupedNotifications: GroupedNotifications = {
        today: [],
        thisWeek: [],
        thisMonth: [],
        otherDays: [],
      };

      notifications.forEach((notification) => {
        const createdDate = new Date(notification.created_at);

        if (createdDate >= startOfToday) {
          groupedNotifications.today.push(notification);
        } else if (createdDate >= startOfWeek) {
          groupedNotifications.thisWeek.push(notification);
        } else if (createdDate >= startOfMonth) {
          groupedNotifications.thisMonth.push(notification);
        } else {
          groupedNotifications.otherDays.push(notification);
        }
      });

      return groupedNotifications;
    };

    const dailyNotify = groupNotificationsByTime(notifications);

    const notificationsRendered = (notifications: TNotifications[]) => {
      if (!notifications) return [];

      const sortedNotifications = notifications.sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
      });

      return sortedNotifications.map((notify) => {
        const url = getNotificationUrl(notify.type, notify.related_object_id);
        return (
          <span
            key={notify.id}
            onClick={() => {
              handleNotificationClick(url);
              onClose?.();
            }}
            style={{ cursor: 'pointer' }} // Optional: Add pointer cursor to make it look clickable
          >
            <NotificationCard
              // icon={getNotificationIcon(notify.type)}
              icon={<NotifyIcon className="fill-primary-purple-blue-500 " />}
              title={notify.title}
              time={notify.created_at}
              message={notify.message}
            />
          </span>
        );
      });
    };

    return (
      <div className={classNames('Notifications', className)} ref={ref}>
        <h3>{t('notifications.title')}</h3>
        <div className="Notifications__wrapper">
          {!!dailyNotify.today.length && (
            <>
              <span>{t('notifications.Today')}</span>
              {notificationsRendered(dailyNotify.today)}
            </>
          )}
          {!!dailyNotify.thisWeek.length && (
            <>
              <span>{t('notifications.This_week')}</span>
              {notificationsRendered(dailyNotify.thisWeek)}
            </>
          )}
          {!!dailyNotify.thisMonth.length && (
            <>
              <span>{t('notifications.This_month')}</span>
              {notificationsRendered(dailyNotify.thisMonth)}
            </>
          )}
          {!!dailyNotify.otherDays.length && (
            <>
              <span>{t('notifications.Other_days')}</span>
              {notificationsRendered(dailyNotify.otherDays)}
            </>
          )}
          {isLoading && <Loader />}
          <div ref={targetRef} className="py-5" />
        </div>
      </div>
    );
  }
);
