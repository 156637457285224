/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getPrivateDailyReward, setPrivateDailyReward } from '../../api/auth';
import { Loader } from '../../components/Loader';
import { sendEarnEDRES } from '../../hooks/useAnalytics';
import useFetch from '../../hooks/useFetch';
import { MButton } from '../../horizon-components/MButton';
import { useUserContext } from '../../providers';
import { useRewardContext } from '../../providers/RewardProvider';
import getErrorMessages from '../../utils/getErrorMessages';
import { DailyRewardCard } from '../../widgets/DailyRewardCard';
import './style.scss';

interface DailyRewardModalProps {
  className?: string;
  onClose?: () => void;
}

export const DailyRewardModal = ({ className, onClose }: DailyRewardModalProps) => {
  const { t } = useTranslation(['translation']);
  const {
    data,
    isLoading,
    fetchData: fetchDailyReward,
  } = useFetch(getPrivateDailyReward);
  const { updateWallet, user } = useUserContext();
  const { rewards, isLoading: isLoadingRewards } = useRewardContext();

  useEffect(() => {
    fetchDailyReward();
  }, [fetchDailyReward, t]);

  const onGetReward = () => {
    setPrivateDailyReward()
      .then(() => {
        // TODO set amount earned
        sendEarnEDRES(0);
        updateWallet();
        toast(t('reward.daily.success'), { type: 'success' });
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => {
        const today = new Date().toLocaleDateString();
        localStorage.setItem(`lastRewardClaim-${user?.guid}`, today);
      });
    onClose?.();
  };

  return (
    <div className={classNames('DailyRewardModal', className)}>
      <div className="DailyRewardModal__head">
        <h1>{t('reward.daily.title')}</h1>
        <p>{t('reward.daily.description')}</p>
      </div>
      {isLoading || isLoadingRewards ? (
        <Loader />
      ) : (
        <>
          <div className="DailyRewardModal__days">
            {data?.map((day, idx) => (
              <DailyRewardCard
                title={`${t('reward.day')} ${idx + 1}`}
                earn={`${
                  rewards?.find(
                    (reward) =>
                      reward.tokenomic_type === 'daily_reward_streak_' + (idx + 1)
                  )?.value || 0
                } EdRes`}
                status={day.status}
                key={idx}
              />
            ))}
          </div>
          <MButton
            variant="highlighted"
            color="primary"
            className="sm-max:w-full md:!px-8 md:!py-6 md:!text-lg"
            onClick={onGetReward}
          >
            {t('buttons.To_collect_the_reward')}
          </MButton>
        </>
      )}
    </div>
  );
};
