export const notificationTimeAgo = (
  dateString: string,
  t: (key: string, data?: {}) => string
) => {
  const now = new Date();
  const publishedDate = new Date(dateString);

  const diffMs = now.getTime() - publishedDate.getTime();
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffSeconds < 60) {
    return t('time.seconds', { count: diffSeconds });
  } else if (diffMinutes < 60) {
    return t('time.minutes', { count: diffMinutes });
  } else if (diffHours < 24) {
    return diffHours === 1 ? t('time.hour') : t('time.hours', { count: diffHours });
  } else if (diffDays === 1) {
    return t('time.yesterday');
  } else if (diffDays < 7) {
    return t('time.days', { count: diffDays });
  } else {
    const months = [
      t('months.jan'),
      t('months.feb'),
      t('months.mar'),
      t('months.apr'),
      t('months.may'),
      t('months.jun'),
      t('months.jul'),
      t('months.aug'),
      t('months.sep'),
      t('months.oct'),
      t('months.nov'),
      t('months.dec'),
    ];
    const day = publishedDate.getDate();
    const month = publishedDate.getMonth();

    return `${day} ${months[month]}`;
  }
};
