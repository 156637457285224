import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MobileAppBanner } from '../../../components/MobileAppBanner/MobileAppBanner';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';
import { MobileNavBar } from '../../../features/MobileNavBar';
import { ShareFriends } from '../../../features/ShareFriends';
import { useShareReferal } from '../../../hooks/useShareReferal';
import { Link } from '../../../horizon-components/Link';
import { Modal } from '../../../horizon-components/Modal';
import Navbar from '../../../horizon-layout/MainLayout/Navbar';
import { MainRoutes } from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useModal } from '../../../providers/ModalProvider';
import getPlatform from '../../../utils/getPlatform';
import { Footer } from '../../../widgets/Footer';
import { MainPageEarn } from '../../../widgets/MainPageEarn';
import { MainPageLearn } from '../../../widgets/MainPageLearn';
import { MainPageLearnMore } from '../../../widgets/MainPageLearnMore';
import { MainPageTeach } from '../../../widgets/MainPageTeach';
import { RewardModal } from '../../../widgets/RewardModal';
import './Main.scss';

interface MainProps {
  className?: string;
}

export const Main = ({ className }: MainProps) => {
  const { t } = useTranslation(['translation']);
  const { closeModal } = useModal();
  const { url, activationRewardAmount } = useShareReferal();
  const platform = getPlatform();

  return (
    <>
      <div className={classNames('Main', className)}>
        <MobileAppBanner />
        <Navbar />
        <div className={classNames('Main__wrapper', className)}>
          <div className="grid gap-2 lg:grid-cols-2 lg:gap-6">
            <MainPageEarn hasIcon className="!hidden lg:!flex" />
            <div className="grid gap-4 lg:order-2 lg:gap-6">
              <MainPageLearn hasIcon />
              <MainPageEarn hasIcon className="!flex lg:!hidden" />
              {[PlatformsEnum.WEB, PlatformsEnum.ANDROID].includes(platform) && (
                <MainPageTeach hasIcon />
              )}
              <ShareFriends />
            </div>
          </div>
          <MainPageLearnMore className="!flex lg:!hidden" />
          {platform !== PlatformsEnum.TELEGRAM && (
            <Link to={MainRoutes.support} className={'text-[14px] font-semibold'}>
              {t('support.support')}
            </Link>
          )}
          <Footer className={'lg-max:hidden'} />
        </div>
        <MobileNavBar />
        <Modal modalId="registration-reward">
          <RewardModal
            onClick={() => closeModal('registration-reward')}
            url={url}
            rewardAmount={activationRewardAmount}
          />
        </Modal>
      </div>
    </>
  );
};
