import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Notification } from '../../../assets/icons/notification.svg';
import { Card } from '../../../horizon-components/Card/Card';
import { Link } from '../../../horizon-components/Link';
import {
  AuctionRoutes,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { Auction, BidStatusEnum } from '../../../models/Auction';
import { useBidContext } from '../../../providers/BidProvider/BidProvider';
import { CoinIcon } from '../../../components/CoinIcon';

type Props = {
  data: Auction;
};

export const AuctionCard: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation(['translation']);
  const [time, setTime] = useState<Moment>(
    moment(moment(data?.start_datetime).diff(moment()))
  );
  const { getMyBidForAuction } = useBidContext();

  useEffect(() => {
    if (
      data.status === BidStatusEnum.COMPLETED ||
      data.status === BidStatusEnum.ACTIVE
    )
      return;
    const id = setInterval(() => {
      setTime(moment(moment(data?.start_datetime).diff(moment())));
    }, 1000);
    return () => clearInterval(id);
  }, [data?.start_datetime, data.status]);

  return (
    <Link
      to={
        MainRoutes.bid +
        (data.status === BidStatusEnum.COMPLETED && data.number_winners
          ? AuctionRoutes.winningsView
          : AuctionRoutes.auctionView) +
        `/${data.id}`
      }
      className="contents"
    >
      <Card
        extra={`rounded-[24px] max-w-[365px] w-full !shadow-[14px_17px_40px_4px_#7090B014] p-[0px] overflow-hidden cursor-pointer`}
      >
        <div className={'relative overflow-hidden'}>
          <div
            className={
              'absolute left-[12px] top-[12px] flex w-fit items-center gap-[4px] rounded-full bg-purple-100 px-[8px] py-[4px]'
            }
          >
            <Notification />
            <span className={'text-[12px] font-[600] text-purple-700'}>
              {data.status === 'active' ? (
                <>
                  {t('bid.finishIn')} {moment(data.end_datetime).utc().fromNow(true)}
                </>
              ) : data.status === 'planned' ? (
                <>
                  {t('bid.startIn')} {moment(time).utc().format('HH:mm:ss')}
                </>
              ) : (
                <>
                  {t('bid.Ended')}{' '}
                  {moment(data.end_datetime).utc().format('DD MMM yyyy HH:mm')}
                </>
              )}
            </span>
          </div>
          {data.status === 'completed' && data.number_winners && (
            <div
              className={`absolute left-[12px] top-[46px] flex w-fit items-center gap-[4px] rounded-full bg-purple-500 px-[8px] py-[4px]`}
            >
              <span className={'text-[12px] font-[600] text-white'}>
                {data.number_winners} {t('bid.winners')}
              </span>
            </div>
          )}
          <img
            src={
              data.images?.[0]?.image ||
              require('../../../assets/images/banner-placeholder.webp')
            }
            className={'h-[176px] w-full object-cover'}
            alt={'auction'}
          />
        </div>
        <div className={'px-[12px] pb-[16px] pt-[8px]'}>
          <p className={'text-[16px] font-[600] text-gray-900'}>{data.title}</p>
          <div className={'flex justify-between'}>
            <div className={'flex w-full justify-between'}>
              <div>
                <p className={'text-[12px] text-gray-600'}>{t('bid.highestBid')}</p>
                <div className={'flex items-center gap-[4px]'}>
                  <CoinIcon />
                  <span className={'text-[14px] text-gray-600'}>
                    {data.highest_bid || '0'}
                  </span>
                </div>
              </div>
              <div>
                <p className={'text-[12px] text-gray-600'}>{t('bid.yourBid')}</p>
                <div className={'flex items-center gap-[4px]'}>
                  <CoinIcon />
                  <span className={'text-[14px] text-gray-600'}>
                    {getMyBidForAuction(data.id) || '0'}
                  </span>
                </div>
              </div>
              <div>
                <p className={'text-[12px] text-gray-600'}>{t('bid.bidders')}</p>
                <div className={'flex items-center gap-[4px]'}>
                  <span className={'text-[14px] text-gray-600'}>
                    {data.bidders_count}
                  </span>
                </div>
              </div>
            </div>

            {/*<div className={'flex flex-col items-end text-right'}>*/}
            {/*  <p className={'text-[12px] text-gray-600'}>*/}
            {/*    {data.bidders_count} {t('bid.bidders')}*/}
            {/*  </p>*/}
            {/*  <div className={'flex'}>*/}
            {/*    {[...data.bids].splice(0, 3).map((item, key) => (*/}
            {/*      <img*/}
            {/*        key={key}*/}
            {/*        src={*/}
            {/*          item.user.profile_image ||*/}
            {/*          require('../../../assets/images/no-avatar.png')*/}
            {/*        }*/}
            {/*        className={*/}
            {/*          'ml-[-5px] h-[22px] w-[22px] overflow-hidden rounded-full border-[1px] border-white object-cover'*/}
            {/*        }*/}
            {/*        alt={'av'}*/}
            {/*      />*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </Card>
    </Link>
  );
};
