import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { About } from '../../../modules/Bid/About/About';
import { Actual } from '../../../modules/Bid/Actual/Actual';
import { AuctionView } from '../../../modules/Bid/AuctionView/AuctionView';
import { Winnings } from '../../../modules/Bid/Winnings/Winnings';
import { AuctionRoutes, MainRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';
import { BidProvider } from '../../../providers/BidProvider/BidProvider';
import { NotFoundPage } from '../../../components/NotFoundPage';

export const BidRouter: React.FC = () => {
  return (
    <BidProvider>
      <div className="mx-auto w-full grow sm-max:p-2">
        <div className="mx-auto w-full grow">
          <Routes>
            <Route
              path="/"
              element={
                <Navigate to={MainRoutes.bid + AuctionRoutes.actual} replace />
              }
            />
            <Route path={AuctionRoutes.actual} element={<Actual />} />
            <Route path={AuctionRoutes.about} element={<About />} />
            <Route path={AuctionRoutes.winnings} element={<Winnings />} />
            <Route
              path={AuctionRoutes.auctionView + '/:id'}
              element={<AuctionView />}
            />
            <Route
              path={AuctionRoutes.winningsView + '/:id'}
              element={<AuctionView />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
      </div>
    </BidProvider>
  );
};
