import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mainRoutes } from '../../../horizon-layout/MainLayout/routes';
import { MobileTab } from '../../../widgets/MobileTab';
import { TrainingMobile } from '../../../widgets/Training';
import './MobileNavBar.scss';

interface MobileNavBarProps {
  className?: string;
}

export const MobileNavBar = ({ className }: MobileNavBarProps) => {
  const { t } = useTranslation(['translation']);

  const routes: RoutesType[] = useMemo(() => {
    return mainRoutes.map((item) => ({
      ...item,
      name: t(`menu.${item.name}`),
      path: item.path,
    }));
  }, [t]);

  const createLinks = (routes?: RoutesType[]) => {
    return routes?.map((route, idx) => (
      <MobileTab
        title={route.name}
        key={idx}
        icon={route.icon}
        url={route.path}
        items={route.items}
        secondary={route.secondary}
        component={route.component}
        auth={route.auth}
        className={route.layout}
      />
    ));
  };

  return (
    <>
      <div className={classNames('MobileNavBar', className)}>
        {createLinks(routes)}
      </div>
      <TrainingMobile />
    </>
  );
};
