import { Capacitor } from '@capacitor/core';
import { PlatformsEnum } from '../entities/Platforms/platforms';

function getPlatform() {
  const platform = Capacitor.getPlatform() as PlatformsEnum;
  const telegramObject = window.Telegram?.WebApp;

  return !!telegramObject ? PlatformsEnum.TELEGRAM : platform;
}

export default getPlatform;
