import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CourseCreation } from '../../../modules/Edu/CourseCreation/CourseCreation';
import { CourseList } from '../../../modules/Edu/CourseList/CourseList';
import { CoursePerformance } from '../../../modules/Edu/CoursePerfomance/CoursePerformance';
import { CourseRejected } from '../../../modules/Edu/CourseRejected/CourseRejected';
import { EnrolledCoursePage } from '../../../modules/Edu/EnrolledCoursePage/EnrolledCoursePage';
import { LearningProgress } from '../../../modules/Edu/LearningProgress/LearningProgress';
import { Main } from '../../../modules/Edu/Main/Main';
import { NFTList } from '../../../modules/Edu/NFTList/NFTList';
import { NFTViewSchool } from '../../../modules/Edu/NFTView/components/NFTViewSchool';
import { NFTView } from '../../../modules/Edu/NFTView/NFTView';
import {
  SubjectClasses,
  SubjectCourses,
  SubjectSchools,
} from '../../../modules/Edu/Subject';
import { VerifyCourseList } from '../../../modules/Edu/VerifyCourseList/VerifyCourseList';
import { useUserContext } from '../../../providers';
import { EduRoutes, MainRoutes } from './types/routes';
import { Navigate } from '../../../horizon-components/Navigate';
import { NotFoundPage } from '../../../components/NotFoundPage';
import { CourseViewPage } from '../../../modules/Edu/course-view';

export const EduRouter: React.FC = () => {
  const { user } = useUserContext();

  return (
    <div className="mx-auto w-full grow sm-max:p-2">
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={MainRoutes.edu + EduRoutes['digital-passes']} replace />
          }
        />
        <Route
          path={EduRoutes.courses}
          element={
            <>
              {!user && <Main />}
              {user?.user_type === 'USER' && <Main />}
              {user?.user_type === 'INSTITUTION' && <CourseList />}
            </>
          }
        />
        <Route path={EduRoutes.course + '/:id'} element={<CourseViewPage />} />
        <Route path={EduRoutes.enroll + '/:id'} element={<EnrolledCoursePage />} />

        <Route path={EduRoutes['digital-passes']} element={<NFTList />} />
        <Route path={EduRoutes.nft + '/:id'} element={<NFTView />} />
        <Route
          path={EduRoutes.nft + '/:id' + EduRoutes.shcool}
          element={<NFTViewSchool />}
        />

        {/* mobile subject */}
        <Route
          path={EduRoutes.nft + '/:id' + EduRoutes.shcools}
          element={<SubjectSchools />}
        />
        <Route
          path={
            EduRoutes.nft +
            '/:id' +
            EduRoutes.shcools +
            '/:schoolId' +
            EduRoutes.classes +
            '/:classId'
          }
          element={<SubjectClasses />}
        />
        <Route
          path={
            EduRoutes.nft +
            '/:id' +
            EduRoutes.shcools +
            '/:schoolId' +
            EduRoutes.classes +
            '/:classId' +
            EduRoutes.courses +
            '/:categoryId'
          }
          element={<SubjectCourses />}
        />
        {/* mobile subject */}

        <Route path={EduRoutes['my-courses']} element={<CourseList />} />
        <Route path={EduRoutes.verify} element={<VerifyCourseList />} />
        <Route path={EduRoutes.edit + '/:id/*'} element={<CourseCreation />} />

        <Route
          path={EduRoutes.preview + '/:id'}
          element={<EnrolledCoursePage isPreview />}
        />
        <Route
          path={EduRoutes.performance + '/:id'}
          element={<CoursePerformance />}
        />
        <Route path={EduRoutes.rejected + '/:id'} element={<CourseRejected />} />
        <Route path={EduRoutes.progress + '/:id'} element={<LearningProgress />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};
