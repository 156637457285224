import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { saveAssesTokenStorage, saveRefreshTokenStorage } from '../../../api';
import {
  authMe,
  authorizeViaGoogle,
  setPrivateUseReferralCode,
} from '../../../api/auth';
import { getRedirectPath } from '../../../api/sessions';
import { sendLogin, sendSignUp, setUserID } from '../../../hooks/useAnalytics';
import useFetch from '../../../hooks/useFetch';
import { useNavigate } from '../../../hooks/useNavigate';
import { Card } from '../../../horizon-components/Card/Card';
import {
  AuthRoutes,
  MAIN_PAGE_ROUTE,
  MainRoutes,
} from '../../../horizon-layout/MainLayout/Routes/types/routes';
import { useUserContext } from '../../../providers';
import { generateGoogleRedirectUri } from '../SignIn/utils';

const GoogleCallback: React.FC = () => {
  const { saveUser, setFirstLogin, updateWallet } = useUserContext();
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();
  const code = urlSearchParams.get('code');
  const { t } = useTranslation(['translation']);
  const redirectPath = getRedirectPath();

  const refCode = localStorage.getItem('refCode');
  const { fetchData: fetchRefCode } = useFetch((code: string) =>
    setPrivateUseReferralCode(code)
  );

  useEffect(() => {
    (async () => {
      if (!code) {
        navigate(MainRoutes.auth + AuthRoutes.signin);
        return;
      }
      const redirectUri = generateGoogleRedirectUri();
      const {
        access,
        refresh,
        is_signin: isSignin,
      } = await authorizeViaGoogle(code, redirectUri);
      saveAssesTokenStorage(access);
      saveRefreshTokenStorage(refresh);
      const userData = await authMe();
      saveUser(userData);
      setUserID(userData.guid);
      if (refCode && !isSignin) {
        await fetchRefCode(refCode);
        updateWallet();
      }
      if (isSignin) {
        sendLogin('Google');
      } else {
        setFirstLogin(true);
        sendSignUp('Google');
      }
      navigate(redirectPath || MAIN_PAGE_ROUTE);
    })();
  }, [code, redirectPath, refCode]);

  return (
    <Card extra="max-w-[405px] md:max-w-[510px] md:w-[510px] h-max mx-2.5 md:mx-auto mt-[50px] mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
      <span>{t('profile.wait')}</span>
    </Card>
  );
};

export default GoogleCallback;
