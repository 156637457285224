import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../assets/icons/modal/close.svg';
import { ReactComponent as FireIcon } from '../../assets/icons/wiz/fire.svg';
import { ReactComponent as ThunderIcon } from '../../assets/icons/wiz/thunder.svg';
import { MButton } from '../../horizon-components/MButton';
import { Modal } from '../../horizon-components/Modal';
import { useWizContext } from '../../providers/WizProvider/WizProvider';
import { WIZ_LIVE } from '../../shared/const/wiz';
import { QuizStartModal } from '../QuizStartModal';
import { QuizTestModal } from '../QuizTestModal';
import './style.scss';

interface QuizHeaderProps {
  className?: string;
}

export const QuizHeader = ({ className }: QuizHeaderProps) => {
  const { t } = useTranslation(['translation']);
  const [isStreakModalOpen, setIsStreakModalOpen] = useState(false);
  const [isEnergyModalOpen, setIsEnergyModalOpen] = useState(false);
  const {
    livesCount,
    nextLifeRecoveryTime,
    currentDay,
    currentMultiplier,
    fetchLivesData,
    fetchForceCompleteQuiz,
    currentTest,
  } = useWizContext();

  const targetDate = nextLifeRecoveryTime
    ? new Date(nextLifeRecoveryTime).getTime()
    : null;

  const [timeLeft, setTimeLeft] = useState(
    targetDate ? targetDate - new Date().getTime() : 0
  );

  useEffect(() => {
    if (!targetDate) return;

    const timerId = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;
      if (difference <= 0) {
        clearInterval(timerId);
        setTimeLeft(0);
        fetchLivesData();
      } else {
        setTimeLeft(difference);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [targetDate]);

  useEffect(() => {
    if (nextLifeRecoveryTime) {
      const newTargetDate = new Date(nextLifeRecoveryTime).getTime();
      setTimeLeft(newTargetDate - new Date().getTime());
    }
  }, [nextLifeRecoveryTime]);

  const formatTime = (milliseconds: number) => {
    if (milliseconds <= 0) return '0:00:00';

    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className={classNames('QuizHeader', className)}>
      <div className="QuizHeader__item">
        <MButton
          variant="highlighted"
          color="primary"
          size="xs"
          className="!rounded-full"
          onClick={() => setIsStreakModalOpen(true)}
        >
          {currentDay} <span className="sm-max:hidden">{t('wiz.days')}</span>{' '}
          <FireIcon />
        </MButton>
      </div>
      <div className="QuizHeader__item justify-end">
        <span>{livesCount < WIZ_LIVE && timeLeft > 0 && formatTime(timeLeft)}</span>
        <MButton
          variant="secondary"
          color="primary"
          size="xs"
          className="!rounded-full"
          onClick={() => setIsEnergyModalOpen(true)}
        >
          {livesCount} <ThunderIcon />
        </MButton>
        {currentTest && (
          <MButton
            variant="secondary"
            color="primary"
            size="xs"
            className="!rounded-full !p-2"
            onClick={fetchForceCompleteQuiz}
          >
            <CloseIcon />
          </MButton>
        )}
      </div>
      <Modal
        isOpen={isStreakModalOpen}
        onClose={() => setIsStreakModalOpen(false)}
        showClose={false}
      >
        <QuizStartModal
          currentDayNumber={currentDay}
          multiplier={currentMultiplier}
          onStart={() => setIsStreakModalOpen(false)}
        />
      </Modal>
      <Modal
        isOpen={isEnergyModalOpen}
        onClose={() => setIsEnergyModalOpen(false)}
        showClose={false}
      >
        <QuizTestModal onClose={() => setIsEnergyModalOpen(false)} />
      </Modal>
    </div>
  );
};
