import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { saveUserStorage } from '../../api';
import { changeUserMeta, setPrivateUseReferralCode } from '../../api/auth';
import { ReactComponent as ERIcon } from '../../assets/icons/new-er.svg';
import { UploadAvatar } from '../../entities/User';
import { MButton } from '../../horizon-components/MButton';
import { MInput } from '../../horizon-components/MInput/MInput';
import { useUserContext } from '../../providers';
import { useModal } from '../../providers/ModalProvider';
import { useRewardContext } from '../../providers/RewardProvider';
import getErrorMessages from '../../utils/getErrorMessages';
import './style.scss';

interface StartModalProps {
  className?: string;
}

export const StartModal = ({ className }: StartModalProps) => {
  const { t } = useTranslation(['translation']);
  const { user, setFirstLogin, updateWallet } = useUserContext();
  const [username, setUsername] = useState<string>(user?.user_meta?.username || '');
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { rewards } = useRewardContext();
  const { closeModal } = useModal();

  const getRefCode = (url: string) => {
    const refCodeMatch = url.match(/ref-code=([\w-]+)/);

    return refCodeMatch ? refCodeMatch[1] : url;
  };

  const handleSave = () => {
    setIsLoading(true);
    Promise.all([
      code ? setPrivateUseReferralCode(getRefCode(code)) : null,
      changeUserMeta(user?.guid || '', {
        username: username || user?.user_meta?.username,
      }),
    ])
      .then((res) => {
        if (res.length === 2) {
          handleCloseFirstModal();

          if (user && res[1].username) {
            user.user_meta = res[1];
            saveUserStorage(user);

            toast(t('profile.Your_data_is_saved_and_updated'), {
              type: 'success',
            });
          }
          if (res[0] !== null) {
            updateWallet();

            toast(t('profile.The_referral_code_has_been_successfully_activated'), {
              type: 'success',
            });
          }
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleCloseFirstModal = () => {
    setFirstLogin(false);
    closeModal('RefCodeModal');
  };

  const refCodeActivation =
    (rewards &&
      rewards.find((reward) => reward.tokenomic_type === 'referral_code_activation')
        ?.value) ||
    '0';

  return (
    <div className={classNames('StartModal', className)}>
      <div className="StartModal__head">
        <h3>{t('profile.Fill_out_your_profile')}</h3>
      </div>
      <UploadAvatar />
      <MInput
        label={t('profile.username')}
        id="username"
        name={'username'}
        type="text"
        value={username}
        onChange={setUsername}
        inputClassName={'w-full leading-[18px]'}
        wrapperClassName={'w-full'}
      />
      <div className="StartModal__wrapper">
        <span>
          {t('profile.Enter_a_friends_link_and_get')} {parseInt(refCodeActivation)}{' '}
          <ERIcon className="h-[18px] w-[18px] fill-primary-purple-blue-500" />
        </span>
        <MInput
          placeholder={t('profile.Enter_the_code')}
          id="code"
          name={'code'}
          type="text"
          value={code}
          onChange={setCode}
          inputClassName={'w-full leading-[18px]'}
          wrapperClassName={'w-full'}
        />
      </div>
      <MButton
        variant="highlighted"
        color="primary"
        className="w-full lg:!py-[22.6px]"
        disabled={!username || isLoading}
        loading={isLoading}
        onClick={handleSave}
        size="md"
      >
        {t('buttons.Confirm')}
      </MButton>
    </div>
  );
};
