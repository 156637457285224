import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/pages/main/arrow-right.svg';
import IconImg from '../../assets/images/pages/main/video.webp';
import { Link } from '../../horizon-components/Link';
import { MButton } from '../../horizon-components/MButton';
import { MainRoutes } from '../../horizon-layout/MainLayout/Routes/types/routes';
import './style.scss';

interface MainPageLearnProps {
  className?: string;
  hasIcon?: boolean;
}

export const MainPageLearn = ({ className, hasIcon }: MainPageLearnProps) => {
  const { t } = useTranslation(['translation']);

  return (
    <Link to={MainRoutes.edu}>
      <div className={classNames('MainPageLearn', className)}>
        <div className={classNames('MainPageLearn__content', { hasIcon })}>
          <h4>{t('main.Learn')}</h4>

          <p>{t('main.Take_courses_at_ED_and_gain_new_knowledge')}</p>
        </div>

        <img src={IconImg} alt={t('main.Learn')} className="icon" />
        <MButton
          variant="secondary"
          color="primary"
          className="z-10 !flex !py-1.5 lg:!hidden"
        >
          {t('buttons.Find_a_course')} <ArrowRightIcon />
        </MButton>
        <MButton
          variant="highlighted"
          color="primary"
          className="z-10 !hidden lg:!flex"
        >
          {t('buttons.Find_a_course')} <ArrowRightIcon />
        </MButton>
      </div>
    </Link>
  );
};
