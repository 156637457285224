import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';
import { ReactComponent as ERIcon } from '../../../assets/icons/new-er.svg';
import Arrow1Img from '../../../assets/images/training/arrow-1.webp';
import Arrow2Img from '../../../assets/images/training/arrow-2.webp';
import Arrow3Img from '../../../assets/images/training/arrow-3.webp';
import { useUserContext } from '../../../providers';
import { isMobile } from '../../../shared/utils/isMobile';
import './style.scss';

interface TrainingProps {
  className?: string;
}

export const Training = ({ className }: TrainingProps) => {
  const { t } = useTranslation(['translation']);
  const { firstLogin, setIsTrained } = useUserContext();
  const [run, setRun] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const beacon = document.querySelector('.react-joyride__beacon');

  const steps: Step[] = [
    {
      target: '#wiz-btn',
      title: t('training.Game'),
      content: (
        <div>
          <Trans
            i18nKey="training.Play_the_Wiz_quiz"
            components={{
              1: <ERIcon className="h-[18px] w-[18px] fill-white" />,
            }}
          />
          <img
            src={Arrow1Img}
            alt={t('training.Game')}
            className="absolute -top-7 right-28"
          />
        </div>
      ),
    },
    {
      target: '#edu-btn',
      title: t('training.Study'),
      content: (
        <div>
          <div dangerouslySetInnerHTML={{ __html: t('training.Take_courses_at') }} />
          <img
            src={Arrow2Img}
            alt={t('training.Study')}
            className="absolute -top-7 right-16"
          />
        </div>
      ),
    },
    {
      target: '.profile',
      title: t('training.Profile'),
      content: (
        <div>
          <Trans
            i18nKey="training.Earn_even_more"
            components={{
              1: <ERIcon className="h-[18px] w-[18px] fill-white" />,
            }}
          />
          <img
            src={Arrow3Img}
            alt={t('training.Profile')}
            className="absolute -right-72 -top-10"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    firstLogin && setRun(firstLogin);
  }, [firstLogin]);

  useEffect(() => {
    if (run && beacon) {
      (beacon as HTMLElement).click();
    }
  }, [beacon, run]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, status } = data;

    if (status === 'running') {
      setCurrentStep(index);
    }

    if (status === 'finished') {
      setRun(false);
      setIsTrained(true);
    }

    if (status === 'skipped') {
      setIsTrained(true);
    }
  };

  if (isMobile()) return null;

  return (
    <div className={classNames('Training', className)}>
      <ReactJoyride
        steps={steps}
        run={run}
        continuous
        showSkipButton
        disableCloseOnEsc
        disableOverlayClose
        hideCloseButton
        hideBackButton
        disableScrolling
        callback={handleJoyrideCallback}
        styles={{
          options: {
            backgroundColor: '#ffffff00',
            textColor: '#fff',
            zIndex: 1000,
          },
          overlay: {
            backgroundColor: '#11005ca8',
          },
          spotlight: {
            borderRadius: currentStep === 2 ? '40px' : '20px',
            maxHeight: currentStep === 2 ? '60px' : 'auto',
            marginTop: currentStep === 2 ? '12px' : 'auto',
            marginLeft: currentStep === 2 ? '-10px' : 'auto',
          },
          buttonNext: {
            display: 'flex',
            padding: '12px 24px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '16px',
            background: '#FFF',
            marginRight: currentStep === 2 ? 'auto' : '36%',

            color: '#4318FF',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '18px',
          },
          buttonSkip: {
            display: 'flex',
            padding: '10px 22px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '16px',
            border: '2px solid #FFF',

            color: '#fff',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '18px',
          },
          tooltip: {
            textAlign: 'left',
            padding: 0,
            paddingTop: currentStep === 2 ? '20px' : '60px',
            right: currentStep === 2 ? '300px' : 'auto',
          },
          tooltipTitle: {
            fontSize: '48px',
            fontWeight: 700,
            textAlign: 'left',
          },
          tooltipContent: {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '22px',
            textAlign: 'left',
            padding: '16px 0',
          },
          beaconInner: {
            backgroundColor: '#4318ff',
          },
          beaconOuter: {
            backgroundColor: '#4318ff63',
            border: '2px solid #4318ff',
          },
        }}
        locale={{
          back: t('buttons.Back'),
          close: t('buttons.Close'),
          last: t('buttons.Complete'),
          next: t('buttons.Next'),
          skip: t('buttons.Skip'),
          open: t('buttons.Start'),
        }}
      />
    </div>
  );
};
