/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEdresWalletsTransactions } from '../../api/auth';
import TransactionsImg from '../../assets/images/not-found/transactions.webp';
import { DatePicker } from '../../components/DatePicker';
import { Loader } from '../../components/Loader';
import { NotFound } from '../../components/NotFound/NotFound';
import useFetch from '../../hooks/useFetch';
import { WalletTable } from '../WalletTable';
import './style.scss';

interface DetailTransactionModalProps {
  className?: string;
}

export const DetailTransactionModal = ({
  className,
}: DetailTransactionModalProps) => {
  const { t } = useTranslation(['translation']);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const {
    data,
    isLoading,
    fetchData: fetchTransactions,
  } = useFetch((min?: string, max?: string) =>
    getEdresWalletsTransactions(min, max)
  );

  useEffect(() => {
    if (selectedDates.length === 2) {
      const arr = JSON.parse(JSON.stringify(selectedDates));
      const dates = {
        min: moment(new Date(arr[0])).format('YYYY-MM-DD'),
        max: moment(new Date(arr[1]).setDate(new Date(arr[1]).getDate() + 1)).format(
          'YYYY-MM-DD'
        ),
      };
      fetchTransactions(dates.min, dates.max);
    }
  }, [selectedDates]);

  useEffect(() => {
    fetchTransactions();
  }, []);

  return (
    <>
      <div className={classNames('DetailTransactionModal', className)}>
        {isLoading ? (
          <Loader />
        ) : !!data?.length ? (
          <WalletTable data={data} />
        ) : (
          <NotFound
            text={t('notifications.You_dont_have_any_transactions')}
            img={TransactionsImg}
          />
        )}
      </div>
      <div className="mt-5 flex w-full items-center justify-center">
        {/* <DatePicker
          selectedDates={selectedDates}
          onDateChange={setSelectedDates}
          configs={{ dateFormat: 'dd.MM' }}
          className="!w-full !justify-center"
          placeholder={t('profile.Show_data_for_the_period')}
          defaultIsOpen
        /> */}
      </div>
    </>
  );
};
