import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { saveUserStorage } from '../../../../api';
import { changeUserMetaAvatar } from '../../../../api/auth';
import { DropImg } from '../../../../features/ProfileCard/ui/DropImg';
import { useUserContext } from '../../../../providers';
import { fileToBase64 } from '../../../../utils/fileToBase64';
import getErrorMessages from '../../../../utils/getErrorMessages';
import AvatarImg from '../../../../assets/images/user/avatar.png';
import './style.scss';

interface UploadAvatarProps {
  className?: string;
}

export const UploadAvatar = ({ className }: UploadAvatarProps) => {
  const { t } = useTranslation(['translation']);
  const { user } = useUserContext();
  const [avatar, setAvatar] = useState<File>();

  const handleDropAvatar = (file: File[]) => {
    setAvatar(file[0]);
    onUploadAvatar(file[0]);
  };

  const onUploadAvatar = async (file: File) => {
    changeUserMetaAvatar(user?.guid || '', {
      ...{ profile_image: file as File },
    })
      .then(async (data) => {
        if (user) {
          user.user_meta.profile_image = data.profile_image
            ? await fileToBase64(data.profile_image)
            : null;
          saveUserStorage(user);

          toast(t('profile.success'), {
            type: 'success',
          });
        }
      })
      .catch((error) => {
        toast(getErrorMessages(error?.response?.data), {
          type: 'error',
        });
      });
  };

  return (
    <div className={classNames('UploadAvatar', className)}>
      <DropImg onDrop={handleDropAvatar} edit={false} className="UploadAvatar__icon">
        {!avatar && !user?.user_meta?.profile_image ? (
          <img
            className="absolute left-0 h-full w-full rounded-full object-cover"
            src={AvatarImg}
            alt={user?.user_meta.username}
          />
        ) : (
          <img
            className="absolute left-0 h-full w-full rounded-full object-cover"
            src={
              avatar
                ? URL.createObjectURL(avatar)
                : user?.user_meta?.profile_image || ''
            }
            alt={user?.user_meta.username}
          />
        )}
      </DropImg>
    </div>
  );
};
