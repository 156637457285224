import { FC } from 'react';
import { ButtonsProps } from '../types';
import { MButton } from '../../../horizon-components/MButton';
import { ReactComponent as Icon } from '../../../assets/icons/share/stories.svg';
import { useTranslation } from 'react-i18next';
import getPlatform from '../../../utils/getPlatform';
import { PlatformsEnum } from '../../../entities/Platforms/platforms';

const ShareTelegramStoriesButton: FC<ButtonsProps> = ({
  loading,
  onSocialClick,
}) => {
  const { t } = useTranslation();
  const WebAppUser = window.Telegram!.WebApp.initDataUnsafe.user;
  const isPremium = !!WebAppUser?.is_premium;

  // In Telegram Stories only Premium users can attach links
  const onClick = async (url: string, text?: string) => {
    if (getPlatform() !== PlatformsEnum.TELEGRAM || !isPremium) return;
    const WebApp = window.Telegram!.WebApp;
    // TODO refactore social share in TG!!!
    console.log(url);
    const urlAr = url.split('t.me');
    if (urlAr.length > 1) {
      url = `https://t.me${urlAr[1]}`;
    }
    WebApp.shareToStory(`${window.location.origin}/icons/prev.png`, {
      text,
      widget_link: { url, name: 'EdRes' },
    });
  };

  return (
    <MButton
      size="sm"
      variant="secondary"
      color="tab"
      onClick={onSocialClick('Telegram Stories', onClick)}
      loading={loading}
      disabled={loading || !isPremium}
    >
      {t(`main.share.${isPremium ? 'publishStories' : 'storiesIsNotAvailable'}`)}{' '}
      {!loading && <Icon />}
    </MButton>
  );
};

export default ShareTelegramStoriesButton;
