import { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { DEFAULT_LANG, LANG_KEY, Languages } from '../i18n';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface LanguageContextProps {
  language: Languages;
  setLanguage: (language: Languages) => void;
}

const LanguageContext = createContext<LanguageContextProps | undefined>(undefined);

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

interface LanguageProviderProps {
  children: ReactNode;
}

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [language, setLanguage] = useState<Languages>(i18n.language as Languages);

  useEffect(() => {
    const currentLang = i18n.language as Languages;
    const { pathname, search } = location;
    const hash = window.location.hash;

    const languagePrefixFromPath = pathname.split('/')[1] as Languages;

    const isAnyLanguageIncludedInPath = Object.values(Languages).includes(
      languagePrefixFromPath
    );
    if (isAnyLanguageIncludedInPath && languagePrefixFromPath !== language) {
      i18n.changeLanguage(languagePrefixFromPath);
      setLanguage(languagePrefixFromPath);
    } else if (!isAnyLanguageIncludedInPath) {
      const newPathname = `/${currentLang}${pathname}`;
      setLanguage(currentLang);
      navigate(newPathname + search + hash, { replace: true });
    }
  }, [i18n, language, location, navigate]);

  const handleSetLanguage = (newLanguage: Languages) => {
    if (newLanguage !== language) {
      const hash = window.location.hash;
      const newPathname = `/${newLanguage}${location.pathname.replace(/^\/[^/]+/, '')}`;
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
      navigate(newPathname + location.search + hash, { replace: true });
    }
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: handleSetLanguage }}>
      <Helmet>
        <html lang={i18n.language}></html>
      </Helmet>
      {children}
    </LanguageContext.Provider>
  );
};
