import React from 'react';
import { LANG_KEY, Languages, LANGUAGES } from '../../i18n';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../providers/LanguageProvider';
import { MButton } from '../../horizon-components/MButton';

export const ChangeLanguage = () => {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useLanguage();

  const setLang = (lang: Languages) => {
    localStorage.setItem(LANG_KEY, lang);
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <div className="flex items-center justify-between">
      {Object.keys(LANGUAGES).map((key) => (
        <MButton
          className={`!border-[0] !px-2.5 !py-1.5 ${language === LANGUAGES[key as Languages]}`}
          variant={language === key ? 'secondary' : 'transparent'}
          onClick={() => setLang(key as Languages)}
          key={key}
        >
          <span className={'text-sm font-semibold md:text-sm'}>
            {LANGUAGES[key as Languages]}
          </span>
        </MButton>
      ))}
    </div>
  );
};
