import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { notificationTimeAgo } from '../../../shared/utils/notificationTimeAgo';
import './NotificationCard.scss';

interface NotificationCardProps {
  className?: string;
  title?: string;
  message?: string;
  time?: string;
  icon?: ReactNode;
}

export const NotificationCard = ({
  className,
  title,
  message,
  time,
  icon,
}: NotificationCardProps) => {
  const { t } = useTranslation(['translation']);
  return (
    <div className={classNames('NotificationCard', className)}>
      {icon && <div className="NotificationCard__icon">{icon}</div>}
      <div className="NotificationCard__content">
        {title && <h4>{title}</h4>}
        {message && <p>{message}</p>}
      </div>
      {time && <div className="text-xs">{notificationTimeAgo(time, t)}</div>}
    </div>
  );
};
