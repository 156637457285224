import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactJoyride, { CallBackProps, Step } from 'react-joyride';
import { ReactComponent as ERIcon } from '../../../assets/icons/new-er.svg';
import { useUserContext } from '../../../providers';
import { isMobile } from '../../../shared/utils/isMobile';
import './style.scss';

interface TrainingMobileProps {
  className?: string;
}

export const TrainingMobile = ({ className }: TrainingMobileProps) => {
  const { t } = useTranslation(['translation']);
  const { firstLogin, setIsTrained } = useUserContext();
  const [run, setRun] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const beacon = document.querySelector('.react-joyride__beacon');

  const steps: Step[] = [
    {
      target: '.menu-wiz',
      title: t('training.Game'),
      content: (
        <Trans
          i18nKey="training.Play_the_Wiz_quiz"
          components={{
            1: <ERIcon className="h-[18px] w-[18px] fill-white" />,
          }}
        />
      ),
    },
    {
      target: '.menu-ed',
      title: t('training.Study'),
      content: (
        <div dangerouslySetInnerHTML={{ __html: t('training.Take_courses_at') }} />
      ),
    },
    {
      target: '.menu-profile',
      title: t('training.Profile'),
      content: (
        <Trans
          i18nKey="training.Earn_even_more"
          components={{
            1: <ERIcon className="h-[18px] w-[18px] fill-white" />,
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    firstLogin && setRun(firstLogin);
  }, [firstLogin]);

  useEffect(() => {
    if (run && beacon) {
      (beacon as HTMLElement).click();
    }
  }, [beacon, run]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, status } = data;

    if (status === 'running') {
      setCurrentStep(index);
    }

    if (status === 'finished') {
      setRun(false);
      setIsTrained(true);
    }

    if (status === 'skipped') {
      setIsTrained(true);
    }
  };

  if (!isMobile()) return null;

  return (
    <div className={classNames('Training', className)}>
      <ReactJoyride
        steps={steps}
        run={run}
        continuous
        showSkipButton
        disableCloseOnEsc
        disableOverlayClose
        hideCloseButton
        hideBackButton
        disableScrolling
        callback={handleJoyrideCallback}
        styles={{
          options: {
            backgroundColor: '#ffffff00',
            textColor: '#fff',
            zIndex: 1000,
            width: '100%',
          },
          overlay: {
            backgroundColor: '#11005ca8',
          },
          spotlight: {
            borderRadius: '20px',
            maxHeight: '50px',
            marginTop: '10px',
            marginLeft: 'auto',
          },
          buttonNext: {
            display: 'flex',
            padding: '12px 24px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '16px',
            background: '#FFF',
            width: '50%',
            color: '#4318FF',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '18px',
          },
          buttonSkip: {
            display: 'flex',
            padding: '10px 22px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '16px',
            border: '2px solid #FFF',
            width: '95%',
            color: '#fff',
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '18px',
          },
          tooltip: {
            position: 'fixed',
            textAlign: 'left',
            padding: 0,
            left: 0,
            right: 0,
            transform: `translate(${currentStep === 1 ? '-62%' : currentStep === 2 ? '-90%' : '-35%'}, -100%)`,
            bottom: '50%',
            width: '330px',
            maxWidth: 'initial',
          },
          tooltipTitle: {
            fontSize: '48px',
            fontWeight: 700,
            textAlign: 'left',
          },
          tooltipContent: {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '22px',
            textAlign: 'left',
            padding: '16px 0',
          },
          beaconInner: {
            backgroundColor: '#4318ff',
          },
          beaconOuter: {
            backgroundColor: '#4318ff63',
            border: '2px solid #4318ff',
          },
          beacon: {
            bottom: '0',
          },
        }}
        locale={{
          back: t('buttons.Back'),
          close: t('buttons.Close'),
          last: t('buttons.Complete'),
          next: t('buttons.Next'),
          skip: t('buttons.Skip'),
          open: t('buttons.Start'),
        }}
      />
    </div>
  );
};
